



































import {Component, Vue} from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import Card from "@/components/Card.vue";
import {setPageTitle} from "@/utils/meta";

@Component({
  components: {Card},
  mixins: [formatter]
})
export default class RbacIndex extends Vue {

  loading = true;
  rbac = null;

  created() {
    setPageTitle("Permissions");
    this.getRbac();
  }

  getRbac() {
    this.$api.get(`rbac`).then(res => {
      this.rbac = res.data;
      this.loading = false;
    });
  }

  hasPermission(role: string, permission: string) {
    return !(this.rbac.assignments[role][permission] === undefined);
  }

  togglePermission(role: string, permission: string) {
    this.$api.post(`rbac/toggle`, {
      role,
      permission
    }).then(() => {
      this.getRbac();
    })

  }

}
